var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      [
        _c("div", {
          staticClass: "are-no-contract",
          domProps: {
            innerHTML: _vm._s(_vm.vip_statement_content.title),
          },
        }),
        _c(
          "p",
          {
            staticClass: "txt-statement-title mb-2",
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.product_name) +
                " " +
                _vm._s(_vm.$t("class.class_statement")) +
                " "
            ),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "text-center mb-5 font-weight-bold",
          },
          [
            _vm._v(
              " （ " +
                _vm._s(_vm.$t("not_applicable")) +
                " Wushare " +
                _vm._s(_vm.$t("vip.vip")) +
                "） "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "are-class-statement",
          },
          _vm._l(_vm.vip_statement_content.content, function (content) {
            return _c(
              "div",
              {
                key: content.title,
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "txt-statement-head",
                  },
                  [_vm._v(_vm._s(content.title) + "：")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "txt-statement-content",
                  },
                  [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(content.content),
                      },
                    }),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm.vipAdditionTermRecord.vipContractAdditionTermRecords &&
        _vm.vipAdditionTermRecord.vipContractAdditionTermRecords.length > 0
          ? _c(
              "div",
              {
                staticClass: "mt-4 d-flex justify-content-center",
              },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName:
                          "v-b-modal.supplemental_agreement_history_modal",
                        modifiers: {
                          supplemental_agreement_history_modal: true,
                        },
                      },
                    ],
                    staticClass: "btn btn-primary mx-auto",
                    attrs: {
                      type: "button",
                    },
                  },
                  [_vm._v("增補協議歷程")]
                ),
              ]
            )
          : _vm._e(),
      ],
      _c("supplemental-agreement-history"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }