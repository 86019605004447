var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "supplemental_agreement_history_modal",
            size: "lg",
            effect: "fade",
            title: "查看協議歷程",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "table-container",
            },
            [
              _c(
                "table",
                {
                  staticClass: "table table-borderless text-center overflow-y",
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-3 custom-table-list",
                        },
                        [_vm._v("建立日期")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-3 custom-table-list",
                        },
                        [_vm._v("目前狀態")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-3 custom-table-list",
                        },
                        [_vm._v("異動人")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-3 custom-table-list",
                        },
                        [_vm._v("內容")]
                      ),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.vipAdditionTermRecord.vipContractAdditionTermRecords,
                      function (record) {
                        return _c(
                          "tr",
                          {
                            key: record.id,
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "col-lg-3 custom-table-list",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateTime(record.createdAt.date)
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "col-lg-3 custom-table-list",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    record.status === "rejected"
                                      ? "拒絕"
                                      : record.status === "approved"
                                      ? "同意"
                                      : record.status === "waiting"
                                      ? "稍後決定"
                                      : ""
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "col-lg-3 custom-table-list",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    record.userType === "user"
                                      ? "使用者"
                                      : record.userType === "admin"
                                      ? "客服人員"
                                      : "系統"
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "col-lg-3 custom-table-list",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDetailsModal(
                                          record.vipContractAdditionTermId,
                                          record.status
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看內容")]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "cancleBtn",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: _vm.closeBtn,
                  },
                },
                [_vm._v("OK")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "content_modal",
            size: "lg",
            effect: "fade",
            "hide-footer": "",
            title: "內容詳情",
          },
        },
        [
          _vm.currentStatus === "waiting"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "title",
                  },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.vipContractAdditionTerm.title)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content ckeditor-table-style",
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.vipContractAdditionTerm.waitingDirections
                        ),
                      },
                    }),
                  ]
                ),
                _vm.identity !== "parent"
                  ? [
                      _vm.isOnWaiting
                        ? _c(
                            "div",
                            {
                              staticClass: "reply-choices",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mx-1",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.choice,
                                        expression: "choice",
                                      },
                                    ],
                                    staticClass: "mr-1",
                                    attrs: {
                                      type: "radio",
                                      value: "approved",
                                      id: "agreeBtn",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.choice, "approved"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.choice = "approved"
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: {
                                        for: "agreeBtn",
                                      },
                                    },
                                    [_vm._v(" 同意")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mx-1",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.choice,
                                        expression: "choice",
                                      },
                                    ],
                                    staticClass: "mr-1",
                                    attrs: {
                                      type: "radio",
                                      value: "rejected",
                                      id: "rejectBtn",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.choice, "rejected"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.choice = "rejected"
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: {
                                        for: "rejectBtn",
                                      },
                                    },
                                    [_vm._v("拒絕")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "mx-1 mt-3 ckeditor-table-style",
                        },
                        [
                          _c("p", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.choice === "approved",
                                expression: "choice === 'approved'",
                              },
                            ],
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.vipContractAdditionTerm.approvedDirections
                              ),
                            },
                          }),
                          _vm.choice === "approved"
                            ? _c(
                                "div",
                                {
                                  staticClass: "mx-1 mt-3 ckeditor-table-style",
                                },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass: "agree-gift-content",
                                    },
                                    [_vm._v("同意後贈送好禮")]
                                  ),
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.vipContractAdditionTerm.note
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c("p", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.choice === "rejected",
                                expression: "choice === 'rejected'",
                              },
                            ],
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.vipContractAdditionTerm.rejectedDirections
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm.isOnWaiting
                        ? _c(
                            "div",
                            {
                              staticClass: "submit-btn",
                            },
                            [
                              _c(
                                "button",
                                {
                                  attrs: {
                                    disabled: !_vm.choice,
                                  },
                                  on: {
                                    click: _vm.handleSendResults,
                                  },
                                },
                                [_vm._v("送出")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.currentStatus === "approved"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "content ckeditor-table-style",
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.vipContractAdditionTerm.waitingDirections
                        ),
                      },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
          _vm.currentStatus === "rejected"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "content ckeditor-table-style",
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.vipContractAdditionTerm.waitingDirections
                        ),
                      },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }