var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _vm._v(_vm._s(_vm.learning_time.learning_time)),
    _c("br"),
    _vm._v(
      "( " +
        _vm._s(_vm.$t("total")) +
        _vm._s(_vm.$t("class.class_count")) +
        ": " +
        _vm._s(_vm.learning_time.total_sessions) +
        " | " +
        _vm._s(_vm.$t("used")) +
        _vm._s(_vm.$t("class.class_count")) +
        ": " +
        _vm._s(_vm.learning_time.used_sessions) +
        " | " +
        _vm._s(_vm.$t("class.number_of_remaining")) +
        ": " +
        _vm._s(_vm.learning_time.balance_sessions) +
        " )  "
    ),
    _vm.learning_time.status == 1
      ? _c(
          "span",
          {
            staticClass: "badge badge-success badge-pill",
          },
          [_vm._v(_vm._s(_vm.$t("enable")))]
        )
      : _vm.learning_time.status == 0
      ? _c(
          "span",
          {
            staticClass: "badge badge-danger badge-pill",
          },
          [_vm._v(_vm._s(_vm.$t("disable")))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }