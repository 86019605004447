var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "table",
        {
          staticClass: "table table-responsive-sm table-contract",
        },
        [
          _c(
            "tbody",
            [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("vip.vip")) + _vm._s(_vm.$t("name"))),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.vipAccountBaseProfile.chinese_name) +
                      " " +
                      _vm._s(_vm.vipAccountBaseProfile.english_name)
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("contract.name")))]),
                _c("td", [_vm._v(_vm._s(_vm.contract_info.product_name))]),
              ]),
              _vm.isTransferContract()
                ? [
                    _vm.contract_info.total_session !== null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("transfer_session"))),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.contract_info.total_session) +
                                " " +
                                _vm._s(_vm.$t("session"))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("transfer_term")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("original_contract_period"))),
                      ]),
                    ]),
                  ]
                : !_vm.isTransferContract()
                ? [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("contract.purchased_lessons"))),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.contract_info.num_session))]),
                    ]),
                    _vm.contract_info.gift_session !== null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("contract.send_lessons"))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contract_info.gift_session)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.contract_info.total_session !== null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("class.lesson")) +
                                _vm._s(_vm.$t("total")) +
                                _vm._s(_vm.$t("class.class_count"))
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contract_info.total_session)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.contract_info.orig_term !== null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("contract.purchased_end_day")) +
                                "(" +
                                _vm._s(_vm.$t("month")) +
                                ")"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contract_info.orig_term)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.contract_info.gift_term !== null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("contract.send_end_day")) +
                                "(" +
                                _vm._s(_vm.$t("month")) +
                                ")"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contract_info.gift_term)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.contract_info.total_term !== null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("class.lesson")) +
                                _vm._s(_vm.$t("total")) +
                                _vm._s(_vm.$t("period")) +
                                "(" +
                                _vm._s(_vm.$t("month")) +
                                ")"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contract_info.total_term)),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.contract_info.learning_time !== null
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("learn")) + _vm._s(_vm.$t("period"))
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _vm._l(
                          _vm.contract_info.learning_time,
                          function (learning_time, index) {
                            return [
                              index == 0
                                ? [
                                    _c("learning-time", {
                                      attrs: {
                                        learning_time: learning_time,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.contract_info.orig_price !== null
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("contract.plan")) +
                          _vm._s(_vm.$t("price"))
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.contract_info.orig_price))]),
                  ])
                : _vm._e(),
              _vm.contract_info.refund_payment.length !== 0
                ? _c("tr", [
                    _c("th", [_vm._v("退款")]),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-wrap",
                        },
                        _vm._l(
                          _vm.contract_info.refund_payment,
                          function (refund, index) {
                            return _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-danger badge-pill mr-2",
                                class: {
                                  "mb-2":
                                    _vm.contract_info.refund_payment.length > 2,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.changeTimeFormat(refund.paid_date)
                                  ) +
                                    " / $" +
                                    _vm._s(refund.money)
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", [
                _c("th", [
                  _vm._v(
                    _vm._s(_vm.$t("total")) +
                      _vm._s(_vm.$t("discount")) +
                      _vm._s(_vm.$t("price"))
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.onCountDisc(_vm.contract_info)))]),
              ]),
              _vm.contract_info.selling_price !== null
                ? _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("total")) + _vm._s(_vm.$t("price"))),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.contract_info.selling_price))]),
                  ])
                : _vm._e(),
              _vm.contract_info.payment !== null
                ? _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("pay")) + _vm._s(_vm.$t("way"))),
                    ]),
                    _c(
                      "td",
                      [
                        _vm._l(
                          _vm.contract_info.payment,
                          function (payment, index) {
                            return [
                              _c("payment-list", {
                                attrs: {
                                  payment_list: payment,
                                },
                              }),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.contract_info.paid_date !== null
                ? _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("pay")) + _vm._s(_vm.$t("date"))),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.contract_info.paid_date))]),
                  ])
                : _vm._e(),
              _vm.contract_info.purchased !== null
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("purchas")) + _vm._s(_vm.$t("date"))
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.contract_info.purchased))]),
                  ])
                : _vm._e(),
              _vm.contract_info.vip_history_transfer_amount
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("shared")) +
                          _vm._s(_vm.$t("total")) +
                          _vm._s(_vm.$t("class.class_count"))
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        _vm._s(_vm.$t("shared")) +
                          _vm._s(_vm.$t("total")) +
                          _vm._s(_vm.$t("end_day"))
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.contract_info.vip_history_transfer_amount) +
                          " " +
                          _vm._s(_vm.$t("class.class"))
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        _vm._s(
                          _vm.contract_info.vip_history_transfer_month_amount
                        ) +
                          " " +
                          _vm._s(_vm.$t("month")) +
                          " " +
                          _vm._s(_vm.$t("contract.and")) +
                          " " +
                          _vm._s(
                            _vm.contract_info.vip_history_transfer_day_amount
                          ) +
                          " " +
                          _vm._s(_vm.$t("contract.day"))
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.vip_share_modal",
                              modifiers: {
                                vip_share_modal: true,
                              },
                            },
                          ],
                          staticClass: "badge badge-primary badge-pill",
                          staticStyle: {
                            cursor: "pointer",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("view_details")))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.checkHasOwnerContract &&
              _vm.findSameVipProductId(_vm.contract_info) &&
              _vm.vipAdditionTermRecord.vipContractAdditionTermRecords &&
              _vm.vipAdditionTermRecord.vipContractAdditionTermRecords.length >
                0
                ? _c("tr", [
                    _c("th", [_vm._v("增補協議歷程")]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName:
                                "v-b-modal.supplemental_agreement_history_modal",
                              modifiers: {
                                supplemental_agreement_history_modal: true,
                              },
                            },
                          ],
                          staticClass: "btn btn-primary mb-2",
                          staticStyle: {
                            cursor: "pointer",
                          },
                        },
                        [_vm._v("查看歷程")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              !_vm.checkHasOwnerContract &&
              _vm.isContractLastOne &&
              _vm.vipAdditionTermRecord.vipContractAdditionTermRecords &&
              _vm.vipAdditionTermRecord.vipContractAdditionTermRecords.length >
                0
                ? _c("tr", [
                    _c("th", [_vm._v("增補協議歷程")]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName:
                                "v-b-modal.supplemental_agreement_history_modal",
                              modifiers: {
                                supplemental_agreement_history_modal: true,
                              },
                            },
                          ],
                          staticClass: "btn btn-primary mb-2",
                          staticStyle: {
                            cursor: "pointer",
                          },
                        },
                        [_vm._v("查看歷程")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.contract_info.vip_suspensions
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("suspend")) + _vm._s(_vm.$t("record"))
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.vip_suspend_record_modal",
                              modifiers: {
                                vip_suspend_record_modal: true,
                              },
                            },
                          ],
                          staticClass: "badge badge-primary badge-pill",
                          staticStyle: {
                            cursor: "pointer",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("view_details")))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.contract_info.vip_products_adjustment &&
              _vm.contract_info.vip_products_adjustment.action_type ==
                "transfer"
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("transfer")) +
                          " / " +
                          _vm._s(_vm.$t("share"))
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName:
                                "v-b-modal.vip_transfer_and_share_apply_modal",
                              modifiers: {
                                vip_transfer_and_share_apply_modal: true,
                              },
                            },
                          ],
                          staticClass: "badge badge-primary badge-pill",
                          staticStyle: {
                            cursor: "pointer",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("view_details")))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.contract_info.vip_products_adjustment &&
              _vm.contract_info.vip_products_adjustment.action_type ==
                "suspension"
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("suspend")) + _vm._s(_vm.$t("apply"))
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.vip_suspend_apply_modal",
                              modifiers: {
                                vip_suspend_apply_modal: true,
                              },
                            },
                          ],
                          staticClass: "badge badge-primary badge-pill",
                          staticStyle: {
                            cursor: "pointer",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("view_details")))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.contract_info.product_id && _vm.contract_info.product_id !== 0
                ? _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("learn")) + _vm._s(_vm.$t("terms"))),
                    ]),
                    _c(
                      "td",
                      [
                        _c("read-learn-rule", {
                          attrs: {
                            contract_info: _vm.contract_info,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c("supplemental-agreement-history"),
      !_vm.loading
        ? _c("vip-contract-share", {
            attrs: {
              contractInfo: _vm.contract_info,
            },
          })
        : _vm._e(),
      !_vm.loading
        ? _c("vip-contract-suspend-apply", {
            attrs: {
              contractInfo: _vm.contract_info,
            },
          })
        : _vm._e(),
      !_vm.loading
        ? _c("vip-contract-suspend-record", {
            attrs: {
              contractInfo: _vm.contract_info,
            },
          })
        : _vm._e(),
      !_vm.loading
        ? _c("vip-contract-transfer-and-share-apply", {
            attrs: {
              contractInfo: _vm.contract_info,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }