var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _vm.contract_info.payment &&
    _vm.contract_info.payment[0].money ==
      _vm.system_set_fee + _vm.monthly_payment
      ? _c("div", [
          _c(
            "div",
            [
              _c("fee-paid-status", {
                attrs: {
                  payment: _vm.contract_info.payment[0].payment_status,
                  type: "system_set_fee",
                  dollar: _vm.system_set_fee,
                },
              }),
              _c("br"),
              _c("fee-paid-status", {
                attrs: {
                  payment: _vm.contract_info.payment[0].payment_status,
                  type: "monthly_payment",
                  dollar: _vm.monthly_payment,
                },
              }),
            ],
            1
          ),
          _vm.contract_info.payment.length > 1
            ? _c(
                "div",
                [
                  _vm._l(_vm.contract_info.payment, function (payment, index) {
                    return [
                      payment.money == _vm.monthly_payment && !_vm.paidAlready
                        ? _c(
                            "div",
                            {
                              key: index,
                            },
                            [_vm._v(_vm._s(_vm.onGetPaymentPaid(payment)))]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(
                    _vm._s(_vm.$t("spgateway_credit_card_binding_eduction")) +
                      " " +
                      _vm._s(_vm.$t("monthly_payment")) +
                      " $" +
                      _vm._s(_vm.monthly_payment) +
                      _vm._s(_vm.$t("dollar"))
                  ),
                  _vm.paidAlready
                    ? _c(
                        "span",
                        {
                          staticClass: "badge badge-success badge-pill",
                        },
                        [_vm._v(_vm._s(_vm.$t("paid")))]
                      )
                    : _c(
                        "span",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "badge badge-danger badge-pill",
                            },
                            [_vm._v(_vm._s(_vm.$t("unpaid")))]
                          ),
                          _c("unpaid-setting"),
                        ],
                        1
                      ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _c("div", [
          _c(
            "div",
            [
              _c("fee-paid-status", {
                attrs: {
                  payment_status: _vm.contract_info.payment[0].payment_status,
                  type: "system_set_fee",
                  dollar: _vm.system_set_fee,
                },
              }),
            ],
            1
          ),
          _vm.contract_info.payment.length == 1
            ? _c("div", [_c("unpaid-setting")], 1)
            : _vm.contract_info.payment.length > 1
            ? _c(
                "div",
                [
                  _vm._l(_vm.contract_info.payment, function (payment, index) {
                    return [
                      payment.money == _vm.monthly_payment && !_vm.paidAlready
                        ? _c(
                            "div",
                            {
                              key: index,
                            },
                            [_vm._v(_vm._s(_vm.onGetPaymentPaid(payment)))]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(
                    _vm._s(_vm.$t("spgateway_credit_card_binding_eduction")) +
                      " " +
                      _vm._s(_vm.$t("monthly_payment")) +
                      " $" +
                      _vm._s(_vm.monthly_payment) +
                      _vm._s(_vm.$t("dollar"))
                  ),
                  _vm.paidAlready
                    ? _c(
                        "span",
                        {
                          staticClass: "badge badge-success badge-pill",
                        },
                        [_vm._v(_vm._s(_vm.$t("paid")))]
                      )
                    : _c(
                        "span",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "badge badge-danger badge-pill",
                            },
                            [_vm._v(_vm._s(_vm.$t("unpaid")))]
                          ),
                          _c("unpaid-setting"),
                        ],
                        1
                      ),
                ],
                2
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }