var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [_vm.profile.card ? [_c("to-paid")] : [_c("bind-credit-card")]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }