var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "vip_suspend_apply_modal",
        size: "lg",
        effect: "fade",
        "ok-title": _vm.$t("agree"),
        "cancel-title": _vm.$t("disagree"),
        title: _vm.$t("contract.share_detailed_info_list"),
      },
      on: {
        ok: function ($event) {
          return _vm.submitConfirm(3)
        },
        cancel: function ($event) {
          return _vm.submitConfirm(4)
        },
      },
    },
    [
      _vm.contractInfo && _vm.contractInfo.vip_products_adjustment
        ? _c("div", [
            _c(
              "tr",
              {
                staticClass: "row col-lg-12 mb-1",
              },
              [
                _c(
                  "th",
                  {
                    staticClass: "col-lg-3",
                  },
                  [_vm._v(_vm._s(_vm.$t("apply")) + _vm._s(_vm.$t("day")))]
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.contractInfo.vip_products_adjustment.created_at
                      )
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "tr",
              {
                staticClass: "row col-lg-12 mb-1",
              },
              [
                _c(
                  "th",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("contract.suspend_deadline")) +
                        "(" +
                        _vm._s(_vm.$t("month")) +
                        ")"
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.contractInfo.vip_products_adjustment.month_amount
                      ) +
                        " " +
                        _vm._s(_vm.$t("month"))
                    ),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("contract.suspend_deadline")) +
                        "(" +
                        _vm._s(_vm.$t("day")) +
                        ")"
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.contractInfo.vip_products_adjustment.day_amount
                      ) +
                        " " +
                        _vm._s(_vm.$t("contract.day"))
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "tr",
              {
                staticClass: "row col-lg-12 mb-1",
              },
              [
                _c(
                  "th",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("contract.suspend_deadline")) +
                        _vm._s(_vm.$t("start_date"))
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.contractInfo.vip_products_adjustment
                          .suspension_start
                      )
                    ),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("contract.suspend_deadline")) +
                        _vm._s(_vm.$t("end_date"))
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.contractInfo.vip_products_adjustment.suspension_end
                      )
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "tr",
              {
                staticClass: "row col-lg-12",
              },
              [
                _c(
                  "th",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("contract.latest_contract_expiry_date"))
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-lg-3",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.contractInfo.vip_products_adjustment.contract_end
                      )
                    ),
                  ]
                ),
              ]
            ),
            _c("hr"),
            _c(
              "tr",
              {
                staticClass: "row col-lg-12",
              },
              [
                _c(
                  "th",
                  {
                    staticClass: "col-lg-12 mb-3",
                  },
                  [_c("h3", [_vm._v(_vm._s(_vm.$t("precautions")) + ":")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "col-lg-12 mb-4",
                  },
                  [_vm._v(_vm._s(_vm.$t("suspend_record_notice")))]
                ),
                _c(
                  "th",
                  {
                    staticClass: "col-lg-12",
                  },
                  [_vm._v(_vm._s(_vm.$t("contact_wuwow")))]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }