var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.role === _vm.student
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary mb-2",
              on: {
                click: function ($event) {
                  return _vm.readLearnRule(_vm.contract_info)
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("reading")) +
                  _vm._s(_vm.$t("learn")) +
                  _vm._s(_vm.$t("terms"))
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "info",
            show: "",
          },
        },
        [
          _vm.contract_info.learn_rule == "0000-00-00 00:00:00" ||
          !_vm.contract_info.learn_rule
            ? _c("span", [
                _vm._v(_vm._s(_vm.$t("not_agree_reading_learn_rule"))),
              ])
            : _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("you_have")) +
                    " " +
                    _vm._s(_vm.contract_info.learn_rule) +
                    " " +
                    _vm._s(_vm.$t("read_learn_term_notice"))
                ),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }