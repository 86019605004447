var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-body",
        },
        [
          _c(
            "div",
            {
              staticClass: "skeleton-box",
            },
            [
              _c("b-skeleton", {
                staticClass: "mt-3 display-center",
                attrs: {
                  width: "30%",
                },
              }),
              _vm._l(20, function (index) {
                return _c(
                  "div",
                  {
                    key: `skeleton-${index}`,
                    staticClass: "mt-2",
                  },
                  [
                    _c("hr"),
                    _c("b-skeleton", {
                      staticClass: "mt-3 display-center",
                      attrs: {
                        width: "50%",
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }