var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "skeleton",
    {
      attrs: {
        loading: _vm.loading,
      },
    },
    [
      _c(
        "template",
        {
          slot: "skeleton-template",
        },
        [_c("skeleton-template")],
        1
      ),
      _c(
        "template",
        {
          slot: "origin-template",
        },
        [
          _c(
            "div",
            {
              staticClass: "wuwow-card",
            },
            [
              _c(
                "div",
                {
                  staticClass: "wuwow-card-body",
                },
                [
                  _vm.accountRole === "parent"
                    ? _c(
                        "h4",
                        {
                          staticClass: "h4-contract-title",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("student")) +
                              _vm._s(_vm.$t("contract.plan"))
                          ),
                        ]
                      )
                    : _c(
                        "h4",
                        {
                          staticClass: "h4-contract-title",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("my")) +
                              _vm._s(_vm.$t("exclusive")) +
                              _vm._s(_vm.$t("contract.plan"))
                          ),
                        ]
                      ),
                  _vm.contract !== null && _vm.contract
                    ? [
                        _vm.contract.product_id === _vm.permanentlyProductId
                          ? _c("permanently-contract-info", {
                              attrs: {
                                contract_info: _vm.contract,
                                vipAccountBaseProfile:
                                  _vm.vipAccountBaseProfile,
                              },
                            })
                          : _c("contract-info", {
                              attrs: {
                                contract_info: _vm.contract,
                                vipAccountBaseProfile:
                                  _vm.vipAccountBaseProfile,
                              },
                            }),
                        _c("b-pagination", {
                          staticClass: "mt-5",
                          attrs: {
                            align: "center",
                            "total-rows": _vm.contractPagination.total,
                            "per-page": Number(_vm.contractPagination.per_page),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.getContractOwnHistory()
                            },
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ]
                    : [
                        _c("no-contract", {
                          attrs: {
                            newRule: _vm.newRule,
                          },
                        }),
                      ],
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }