var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "vip_share_modal",
        size: "lg",
        effect: "fade",
        "ok-title": _vm.$t("confirm"),
        "ok-only": "",
        title: _vm.$t("contract.share_detailed_info_list_new"),
      },
    },
    [
      _vm.contractInfo && _vm.contractInfo.vip_history
        ? _c(
            "div",
            _vm._l(_vm.contractInfo.vip_history, function (detail) {
              return _c(
                "div",
                {
                  key: detail.id,
                },
                [
                  _c(
                    "tr",
                    {
                      staticClass: "row col-lg-12",
                    },
                    [
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-1",
                        },
                        [_vm._v(_vm._s(_vm.$t("type")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "col-lg-2",
                        },
                        [_vm._v(_vm._s(_vm.transType(detail.action_type)))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-1",
                        },
                        [_vm._v(_vm._s(_vm.$t("related_person")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "col-lg-2",
                        },
                        [_vm._v(_vm._s(detail.transfer_vip_english_name))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-1",
                        },
                        [_vm._v(_vm._s(_vm.$t("class.class_count")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "col-lg-2",
                        },
                        [
                          _vm._v(
                            _vm._s(detail.amount) +
                              " " +
                              _vm._s(_vm.$t("class.class"))
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "col-lg-1",
                        },
                        [_vm._v(_vm._s(_vm.$t("end_day")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "col-lg-2",
                        },
                        [
                          _vm._v(
                            _vm._s(detail.month_amount) +
                              " " +
                              _vm._s(_vm.$t("month")) +
                              _vm._s(_vm.$t("contract.and")) +
                              " " +
                              _vm._s(detail.day_amount) +
                              " " +
                              _vm._s(_vm.$t("contract.day"))
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("hr"),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "h3",
        {
          staticClass: "mt-2",
        },
        [_vm._v(_vm._s(_vm.$t("precautions")) + ":")]
      ),
      _c("br"),
      _c("span", {
        domProps: {
          innerHTML: _vm._s(_vm.$t("transfer_share_notice")),
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }